import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import data from "./data.json";
import profl from "./../../../icons/profl.jpg";
const Table = ({ rowData, page }) => {
  const [add, setAdd] = useState(false);
  console.log("rowData", rowData);
  const navigate = useNavigate();
  const handleSignIn = (borrowerId) => {
    navigate("/b2cAdmin/view-loan-details", {
      state: { borrowerId },
    });
  };
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "linear-gradient(to bottom, #12468a, #235392)",
              zIndex: "5",
              borderBottom: "1px solid #ddd",
            }}
          >
            <tr>
              <th style={{ minWidth: "40px" }}>#</th>

              <th style={{ minWidth: "120px" }}>Borrower ID</th>
              <th style={{ minWidth: "120px" }}>Loan Id</th>
              <th style={{ minWidth: "120px" }}>Loan Status</th>
              <th style={{ minWidth: "120px" }}>Loan Amount</th>
              <th style={{ minWidth: "120px" }}>Product</th>
              <th style={{ minWidth: "120px" }}>EMI Amount</th>
              <th style={{ minWidth: "120px" }}>Tenure</th>
              <th style={{ minWidth: "120px" }}>Paid Amount</th>
              <th style={{ minWidth: "120px" }}>Amount Due</th>
              {/* <th style={{ minWidth: "140px" }}>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, index) => (
              <tr>
                <td>{(page - 1) * 10 + (index + 1)}</td>
                <td>{row.borrower_id}</td>
                <td>{row.loan_id}</td>

                <td>
                  <span
                    style={{
                      backgroundColor:
                        row.status === "Pending"
                          ? "#ffbe0b"
                          : row.status === "Complete"
                          ? "#45cb85"
                          : "#f06548",
                    }}
                    className={styles.statsbtn}
                  >
                    {" "}
                    {row.status}
                  </span>
                </td>
                <td>{row.loan_amount}</td>
                <td>{row.model}</td>
                <td>{row.emi_amount}</td>
                <td>{row.tenure}</td>
                <td>{row.paid_amount}</td>
                <td>{row.amount_due}</td>

                {/* <td>
                  {" "}
                  <button
                    // onClick={() => handleEditRow(row)}
                    className={styles.editbtn}
                  >
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button
                    className={styles.viewbtn}
                    onClick={() => handleSignIn(row?.borrower_id)}
                  >
                    <FaEye
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button className={styles.dltbtn}>
                    <MdDelete
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
