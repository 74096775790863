import React, { useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useNavigate } from "react-router-dom";
import api from "../../Common/api";
import { useQueries } from "@tanstack/react-query";
const PorductForm = ({ defaultValue }) => {
  console.log("defaultValue in", defaultValue);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [
    { data: ManufacturerList },
    { data: BatteryList },
    { data: VehicleList },
    { data: ProductList },
    { data: dealerList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["manufacturer"],
        queryFn: () => api.get("manufacturer-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["batteryList"],
        queryFn: () => api.get("battery-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["vehicleType"],
        queryFn: () => api.get("vehicle-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["prodtype"],
        queryFn: () => api.get("product-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  console.log("dealerList", dealerList);
  useEffect(() => {
    if (defaultValue) {
      setValue("dealer_id", defaultValue?.dealer_id);
      setValue("name", defaultValue?.name);
      setValue("battery_type", defaultValue?.battery_type);
      setValue("ex_showroom_price", defaultValue?.ex_showroom_price);
      setValue("loan_to_value_ratio", defaultValue?.loan_to_value_ratio);
      setValue("manufacture_name", defaultValue?.manufacture_name);
      setValue("min_loan_amount", defaultValue?.min_loan_amount);
      setValue("max_loan_amount", defaultValue?.max_loan_amount);
      setValue("min_loan_tenure", defaultValue?.min_loan_tenure);
      setValue("max_loan_tenure", defaultValue?.max_loan_tenure);
      setValue("product_type", defaultValue?.product_type);
      setValue("interest", defaultValue?.interest);
      setValue("processing_fee", defaultValue?.processing_fee);
      setValue("subvention_amount", defaultValue?.subvention_amount);
      setValue("fldg", defaultValue?.fldg);
      setValue("iot", defaultValue?.iot);
      setValue("insurance_1st_year", defaultValue?.insurance_1st_year);
      setValue("insurance_2nd_year", defaultValue?.insurance_2nd_year);
    }
  }, [
    defaultValue,
    setValue,
    dealerList,
    ManufacturerList,
    BatteryList,
    ProductList,
  ]);

  console.log("ManufacturerList", ManufacturerList);
  console.log("BatteryList", BatteryList);
  console.log("BatteryList", BatteryList);
  const onSubmit = async (data) => {
    let payload;
    payload = {
      dealer_id: data.dealer_id || "",
      name: data.name || "",
      battery_type: data.battery_type || "",
      ex_showroom_price: data.ex_showroom_price || "",
      loan_to_value_ratio: data.loan_to_value_ratio || "",
      manufacture_name: data.manufacture_name || "",
      min_loan_amount: data.min_loan_amount || "",
      max_loan_amount: data.max_loan_amount || "",
      min_loan_tenure: data.min_loan_tenure || "",
      max_loan_tenure: data.max_loan_tenure || "",
      product_type: data.product_type || "",
      interest: data.interest || "",
      processing_fee: data.processing_fee || "",
      subvention_amount: data.subvention_amount || "",
      fldg: data.fldg || "",
      iot: data.iot || "",
      insurance_1st_year: data.insurance_1st_year || "",
      insurance_2nd_year: data.insurance_2nd_year || "",
    };
    console.log("updatedData", payload);
    // updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: defaultValue ? `update-product/${defaultValue.id}` : "store-product",
    method: defaultValue ? "put" : "post",
    invalidateKey: "btryTypeList",
    successCallback: () => {
      reset();
      navigate("/product-list");
    },
  });
  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer<span className={styles.requ}>*</span>
              </label>
              {/* <input
                type="text"
                className={styles.inpttsc}
                {...register("dealer_id", { required: true })}
              /> */}
              <select
                {...register("dealer_id", { required: true })}
                className={styles.mdselecttt}
              >
                <option value="">--Select Dealer--</option>
                {dealerList?.data?.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>

              {errors.dealer_id && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Product Name<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("name", { required: true })}
              />

              {errors.name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Battery Type<span className={styles.requ}>*</span>
              </label>
              {/* <input
                type="text"
                className={styles.inpttsc}
                {...register("battery_type", {
                  required: true,
                })}
              /> */}
              <select
                {...register("battery_type", {
                  required: true,
                })}
                className={styles.mdselecttt}
              >
                <option value="">--Select Battery Type--</option>
                {BatteryList?.data?.map((val) => (
                  <option key={val.id} value={val.battery_type}>
                    {val.battery_type}
                  </option>
                ))}
              </select>
              {errors.battery_type && (
                <span className={styles.error}>"This Field is Missing."</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Ex Showroom Price<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("ex_showroom_price", {
                  required: true,
                })}
                step="0.00"
              />

              {errors.ex_showroom_price && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan To Value Ratio<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                step="0.00"
                {...register("loan_to_value_ratio", { required: true })}
              />
              {errors.loan_to_value_ratio && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Manufacture Name<span className={styles.requ}>*</span>
              </label>
              {/* <input
                type="text"
                className={styles.inpttsc}
                {...register("manufacture_name", {
                  required: true,
                })}
              /> */}
              <select
                {...register("manufacture_name", {
                  required: true,
                })}
                className={styles.mdselecttt}
              >
                <option value="">--Select Manufacture Name--</option>
                {ManufacturerList?.data?.map((val) => (
                  <option key={val.id} value={val.manufacturer}>
                    {val.manufacturer}
                  </option>
                ))}
              </select>
              {errors.manufacture_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Min Loan Amount<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("min_loan_amount", {
                  required: true,
                })}
              />
              {errors.min_loan_amount && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Max Loan Amount<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("max_loan_amount", { required: true })}
              />
              {errors.max_loan_amount && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Min Loan Tenure<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("min_loan_tenure", { required: true })}
              />
              {errors.min_loan_tenure && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Max Loan Tenure<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("max_loan_tenure", {
                  required: true,
                })}
              />
              {errors.max_loan_tenure && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Product Type<span className={styles.requ}>*</span>
              </label>
              {/* <input
                type="text"
                className={styles.inpttsc}
                {...register("product_type", { required: true })}
              /> */}
              <select
                {...register("product_type", { required: true })}
                className={styles.mdselecttt}
              >
                <option value="">--Select Product--</option>
                {ProductList?.data?.map((val) => (
                  <option key={val.id} value={val.product_type}>
                    {val.product_type}
                  </option>
                ))}
              </select>
              {errors.product_type && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Interest<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("interest", { required: true })}
              />
              {errors.interest && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Processing Fee<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("processing_fee", { required: true })}
              />
              {errors.processing_fee && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Subvention Amount<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("subvention_amount", { required: true })}
              />
              {errors.subvention_amount && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                FLDG<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("fldg", { required: true })}
              />
              {errors.fldg && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                IOT<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("iot", { required: true })}
              />
              {errors.iot && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Insurance 1st Year<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                step="0.00"
                className={styles.inpttsc}
                {...register("insurance_1st_year", { required: true })}
              />
              {errors.insurance_1st_year && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Insurance 2nd Year<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("insurance_2nd_year", { required: true })}
              />
              {errors.insurance_2nd_year && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PorductForm;
