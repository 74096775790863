import React from "react";
import styles from "./Table.module.css";
import data from "./data.json";
import { AiFillEdit } from "react-icons/ai";
const Table = ({ rowData }) => {
  return (
    <div
      className={styles.parenttabwrap}
      // style={{ height: "calc(100vh - 320px)" }}
    >
      <div className={styles.tabwrap}>
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "linear-gradient(to bottom, #12468a, #235392)",
              zIndex: "5",
              borderBottom: "1px solid #ddd",
            }}
          >
            <tr>
              <th style={{ minWidth: "40px" }}>#</th>

              <th style={{ minWidth: "120px" }}>Type</th>
              <th style={{ minWidth: "120px" }}>Percentage</th>
              <th style={{ minWidth: "120px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((row, index) => (
              <tr>
                {/* <td>{row.id}</td> */}
                <td>{index + 1}</td>
                <td>{row.date}</td>
                <td>{row.pnd}</td>
                <td>
                  {" "}
                  <button
                    type="button"
                    // onClick={() => handleEditRow(row)}
                    className={styles.editbtn}
                  >
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
