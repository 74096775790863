import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
// import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const ViewDet = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     window.open(pdfUrl, "_blank");
  //   }
  // };
  const handleViewPdf = (pdfUrl) => {
    if (pdfUrl) {
      const fullUrl = `${baseDoc}${pdfUrl}`;
      window.open(fullUrl, "_blank");
    }
  };
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop> */}
      <div>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Name{" "}
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.name}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Type
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.company_type}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Complience Officer Name
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_name}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Complience Officer Mobile
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_mobile}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Complience Officer Email
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.complience_officer_email}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Created At
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.created_at}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Agreement Doc
                {defaultValue && defaultValue?.agreement_doc && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.agreement_doc)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                COI Doc
                {defaultValue && defaultValue?.coi_doc && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.coi_doc)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>

          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                NBFC Certificate
                {defaultValue && defaultValue?.nbfc_certificate && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(defaultValue.nbfc_certificate)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Lender ID
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {defaultValue?.lender_unq}
              </label>
            </div>
            {/* <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Certificate Of Incorporation
                {existingData && existingData?.certificate_of_incorporation && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.certificate_of_incorporation)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Factory Address Proof{" "}
                {existingData && existingData?.factory_address_proof && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.factory_address_proof)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDet;
