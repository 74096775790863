import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import data from "./data.json";
import AddModal from "./AddModal";
const Table = ({ rowData, page, numberOfRecords, perm }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  console.log("rowToEdit", rowToEdit);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "linear-gradient(to bottom, #12468a, #235392)",
              zIndex: "5",
              borderBottom: "1px solid #ddd",
            }}
          >
            <tr>
              <th>#</th>
              <th style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Marital Status</span>
                <FaSort />
              </th>

              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            {rowData?.map((row, index) => (
              <tr>
                <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                <td>{row.status}</td>

                <td>
                  {" "}
                  {perm?.includes("update") && (
                    <button
                      type="button"
                      onClick={() => handleEditRow(row)}
                      className={styles.editbtn}
                    >
                      <AiFillEdit
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </button>
                  )}
                  {perm?.includes("delete") && (
                    <button type="button" className={styles.dltbtn}>
                      <MdDelete
                        size={12}
                        style={{ position: "relative", top: "1px" }}
                      />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={add}
          onClick={addHandle}
        >
          <AddModal addHandle={addHandle} defaultValue={rowToEdit || []} />
        </Backdrop>
      </div>
    </div>
  );
};

export default Table;
