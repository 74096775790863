import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useToast } from "../../../context/ToastProvder";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  const { showToast } = useToast();
  const onSubmit = (data) => {
    const { start_date, end_date } = data;

    // Check if end date is selected without a start date
    if (end_date && !start_date) {
      showToast("Please Select Start Date First");
      return;
    }
    if (end_date && start_date && end_date < start_date) {
      showToast(
        "The end date field must be a date after or equal to start date."
      );
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Start Date
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("start_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              End Date
            </label>
            <input
              type="date"
              className={styles.inpttsc}
              {...register("end_date")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile No
            </label>
            <input
              type="mobile"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <input
              type="email"
              className={styles.inpttsc}
              {...register("email")}
            />
          </div>
        </div>
        <div
          className={styles.twoinputasc}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar No.
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("aadhar")}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#45cb85",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="submit"
          >
            Search
          </button>
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#f06548",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
