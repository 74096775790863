import React from "react";
import styles from "./Table.module.css";
import data from "./data.json";
const Table = ({ rowData, page, numberOfRecords }) => {
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 320px)" }}
    >
      <div className={styles.tabwrap}>
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "linear-gradient(to bottom, #12468a, #235392)",
              zIndex: "5",
              borderBottom: "1px solid #ddd",
            }}
          >
            <tr>
              <th style={{ minWidth: "40px" }}>#</th>

              <th style={{ minWidth: "120px" }}>Date</th>
              <th style={{ minWidth: "120px" }}>Particulars/Description</th>
              <th style={{ minWidth: "120px" }}>Credit</th>
              <th style={{ minWidth: "120px" }}>Debit</th>
              <th style={{ minWidth: "120px" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {data?.data.map((row, index) => (
              <tr>
                <td>{(page - 1) * numberOfRecords + (index + 1)}</td>
                <td>{row.date}</td>
                <td>{row.pnd}</td>
                <td>{row.credit}</td>
                <td>{row.dabit}</td>
                <td>{row.balance}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
