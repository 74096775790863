import React, { useState, useEffect } from "react";
import styles from "./MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { BiCylinder } from "react-icons/bi";
const BottomDetails = () => {
  const applicationData = [
    {
      title: "Loan Pipeline",
      progress: "In Progress",
      value: "N/A",
      color: "#ffbe0b",
    },
    {
      title: "Pending Applications",
      progress: "In Approved",
      value: "N/A",
      color: "#45cb85",
    },
    {
      title: "Approved Applications",
      progress: "Rejected",
      value: "N/A",
      color: "#f06548",
    },
    {
      title: "Rejected Applications",
      progress: "Disbursed",
      value: "N/A",
      color: "#25a546",
    },
  ];
  return (
    <div style={{ marginTop: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {applicationData.map((app, index) => (
          <div className={styles.ttldv}>
            <div className={styles.textconfor}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                {app.title}
              </p>
              <p
                style={{
                  color: "#878a99",
                  fontSize: "11px",
                  paddingTop: "1px",
                }}
              >
                {app.progress}
              </p>
              <div className={styles.btmoftot}>
                <p
                  style={{
                    color: "#495057",
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: "1.2",
                  }}
                >
                  {app.value}
                </p>
                <div
                  style={{ background: app.color }}
                  className={styles.icocontforbt}
                >
                  <BiCylinder size={22} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomDetails;
