import React, { useState, useEffect } from "react";
import styles from "./../MainDashboad/MonthBtn.module.css";
import { TbHexagons } from "react-icons/tb";
import { FaRupeeSign } from "react-icons/fa";
const Totaldash = ({ dashboardList }) => {
  const applicationData = [
    { title: "Total Applications", value: 559.25, color: "#299cdb" },
    { title: "Pending Applications", value: 55, color: "#25a546" },
    { title: "Approved Applications", value: 559, color: "#45cb85" },
    { title: "Rejected Applications", value: 5, color: "#f06548" },
    { title: "Loan Amount", value: 559, color: "#ffbe0b" },
  ];
  return (
    <div style={{ marginBottom: "20px", display: "grid" }}>
      <div className={styles.monthBoxes}>
        {/* {applicationData.map((app, index) => ( */}
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11.5px" }}>
              Current Balance
              {/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                1,800
                {/* {dashboardList?.total_applications} */}
              </p>
              <div
                style={{ background: "#299cdb", width: "35px", height: "35px" }}
                className={styles.icocont}
              >
                <FaRupeeSign size={20} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11.5px" }}>
              Total Credit
              {/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                2,400
                {/* {dashboardList?.pending_applications} */}
              </p>
              <div
                style={{ background: "#45cb85", width: "35px", height: "35px" }}
                className={styles.icocont}
              >
                <FaRupeeSign size={20} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ttldv}>
          <div className={styles.textconfor}>
            <p style={{ color: "#878a99", fontSize: "11.5px" }}>
              Total Debit
              {/* {app.title} */}
            </p>
            <div className={styles.btmoftot}>
              <p
                style={{
                  color: "#495057",
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                600
                {/* {dashboardList?.approved_applications} */}
              </p>
              <div
                style={{ background: "#f06548", width: "35px", height: "35px" }}
                className={styles.icocont}
              >
                <FaRupeeSign size={20} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Totaldash;
