// import React, { useEffect, useState } from "react";
// import styles from "./Modal.module.css";
// import { style } from "@mui/system";
// import { useForm } from "react-hook-form";
// import close from "./../../../icons/close.png";
// import RichTextEditor from "./../../Common/Rich-text Editor/RichTextEditor";
// const AddModal = ({ addHandle, defaultValue }) => {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors },
//     setError,
//     clearErrors,
//     reset,
//     watch,
//   } = useForm();
//   // useEffect(() => {
//   //   if (defaultValue) {
//   //     setValue("startDate", defaultValue.startDate);
//   //     setValue("vpfAmount", defaultValue.amount);
//   //   }
//   // }, [defaultValue, setValue]);

//   const onSubmit = async (data) => {
//     let payload;
//     if (defaultValue) {
//       payload = {
//         employeeId: defaultValue.empId,
//         startDate: data.template,
//         vpfAmount: data.vpfAmount,
//         calculatedOn: "amount",
//       };
//       console.log("updatedData", payload);
//       //  updateVpnMutation.mutate(payload);
//     }
//   };
//   const handleEditorChange = (content) => {
//     if (content) {
//       clearErrors("template");
//     }
//     setValue("template", content);
//   };
//   //  const updateVpnMutation = useCustomMutation({
//   //    url: "mode/employee-vpf-setting/submitVpfSettingForm",
//   //    invalidateKey: "vpfList",
//   //    successCallback: () => {
//   //      reset();
//   //      addHandle();
//   //    },
//   //  });
//   return (
//     <form
//       onSubmit={handleSubmit(onSubmit)}
//       className={styles.modalcontainer}
//       onClick={(event) => event.stopPropagation()}
//     >
//       <div className={styles.modalHeader}>
//         <h3 className={styles.modalheading}>
//           {defaultValue ? "Edit Emi Debit Consent" : "Add Emi Debit Consent"}
//         </h3>
//         <img
//           onClick={addHandle}
//           src={close}
//           style={{
//             width: "11px",
//             position: "relative",
//             top: "3px",
//             cursor: "pointer",
//           }}
//         />
//       </div>
//       <div
//         className={styles.modalbody}
//         style={{ borderBotom: "1px solid #red" }}
//       >
//         <div className={styles.modalform}>
//           <label
//             style={{
//               marginBottom: "0.5rem",
//               color: "#494646",
//               fontSize: "13px",
//             }}
//           >
//             Consent Content
//           </label>
//           <RichTextEditor
//             onTextChange={handleEditorChange}
//             value={watch("template")}
//           />
//           {/* <input
//             {...register("startDate", { required: true })}
//             type="text"
//             className={styles.mdlinput}
//           /> */}
//           {errors.startDate && (
//             <span className={styles.error}>This Field is Missing.</span>
//           )}
//         </div>
//       </div>
//       <div style={{ padding: "10px 15px" }}>
//         <div style={{ display: "flex", justifyContent: "space-between" }}>
//           <p></p>
//           <div style={{ display: "flex", gap: "10px" }}>
//             <button className={styles.closebtn} onClick={addHandle}>
//               Close
//             </button>
//             <button type="submit" className={styles.Subbtn}>
//               Submit
//             </button>
//           </div>
//         </div>
//       </div>
//     </form>
//   );
// };

// export default AddModal;
import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import RichTextEditor from "./../../Common/Rich-text Editor/RichTextEditor";
import useCustomMutation from "../../CustomHook/useCustomMutation";
const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm();
  useEffect(() => {
    if (defaultValue) {
      setValue("template", defaultValue?.content);
    }
  }, [defaultValue, setValue]);
  // useEffect(() => {
  //   if (defaultValue) {
  //     setValue("startDate", defaultValue.startDate);
  //     setValue("vpfAmount", defaultValue.amount);
  //   }
  // }, [defaultValue, setValue]);

  const onSubmit = async (data) => {
    let payload;

    payload = {
      content: data.template,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-emi-debit-content/${defaultValue.id}`
      : "admin/store-emi-debit-content",
    method: defaultValue ? "put" : "post",
    invalidateKey: "langList",
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Emi Debit Consent" : "Add Emi Debit Consent"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <label
            style={{
              marginBottom: "0.5rem",
              color: "#494646",
              fontSize: "13px",
            }}
          >
            Consent Content
          </label>
          <textarea
            {...register("template", { required: true })}
            type="text"
            className={styles.mdlinput}
            style={{ height: "120px" }}
          />
          {errors.template && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
