import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
const DealerForm = ({ defaultValue }) => {
  const { user, token, setUser, setToken, baseURL } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [formdata, setFormdata] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [PFRemovedId, setPFRemovedId] = useState([]);
  const [numForms, setNumForms] = useState(
    formdata?.authorizedDirectors?.length || 1
  );
  const [authorizedDirectors, setAuthorizedDirectors] = useState([
    {
      AuthorizedDirectorsName: "",
      AuthorizedDirectorsNumber: "",
      AuthorizedDirectorsEmail: "",
    },
  ]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();

  const [
    { data: depatmentList },
    { data: userTypeList },
    { data: dealerList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const handleAddForm = () => {
    setNumForms((prevNumForms) => {
      setSelectedOption((prevState) => {
        const newState = { ...prevState };
        newState[prevNumForms] = ""; // Set default value to an empty string
        return newState;
      });
      return prevNumForms + 1;
    });
  };
  const handleMinusForm = (index, id) => {
    const currentDataArray = watch("bonusList");

    if (currentDataArray[index]) {
      setValue(
        "bonusList",
        currentDataArray.filter((_, i) => i !== index)
      );

      setNumForms((prevNumForms) => prevNumForms - 1);
      if (id) {
        setPFRemovedId((prevIds) => [...prevIds, id]);
      }
    } else {
      setNumForms((prevNumForms) => prevNumForms - 1);
    }
  };
  console.log("defaultValue", defaultValue);
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue?.name);
      setValue("company_type", defaultValue?.company_type);
      setValue(
        "complience_officer_email",
        defaultValue?.complience_officer_email
      );
      setValue(
        "complience_officer_mobile",
        defaultValue?.complience_officer_mobile
      );
      setValue(
        "complience_officer_name",
        defaultValue?.complience_officer_name
      );
      setValue("finayo_share", defaultValue?.finayo_share);
      setValue("lender_share", defaultValue?.lender_share);
      setValue("lending_roi", defaultValue?.lending_roi);
      setValue("processing_fee_amount", defaultValue?.processing_fee_amount);
      setValue(
        "processing_fee_sharing_status",
        defaultValue?.processing_fee_sharing_status
      );
      setValue("processing_fee_type", defaultValue?.processing_fee_type);
    }
  }, [defaultValue, setValue]);
  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("company_type", data.company_type);
    formData.append("complience_officer_name", data.complience_officer_name);
    formData.append(
      "complience_officer_mobile",
      data.complience_officer_mobile
    );
    formData.append("complience_officer_email", data.complience_officer_email);
    formData.append(
      "processing_fee_sharing_status",
      data.processing_fee_sharing_status
    );
    formData.append("processing_fee_type", data.processing_fee_type);
    formData.append("finayo_share", data.finayo_share);
    formData.append("lender_share", data.lender_share);
    formData.append("processing_fee_amount", data.processing_fee_amount);
    formData.append("lending_roi", data.lending_roi);

    // formData.append("AuthorizedDicgjnectors", JSON.stringify(data.bonusList));

    // if (peri && peri.id) {
    //   formData.append("id", peri.id);
    // }

    // Append file fields
    const fileFields = ["agreement_doc", "coi_doc", "nbfc_certificate"];
    const MAX_FILE_SIZE = 3072 * 1024; // 3072 KB
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 3MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-lender/${defaultValue?.id}`
        : `${baseURL}admin/store-lender`;
      const method = defaultValue ? "put" : "post";
      // const response = await axios.post(apiEndpoint, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/lenders");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  useEffect(() => {
    if (defaultValue) {
      const fileFields = ["agreement_doc", "coi_doc", "nbfc_certificate"];

      fileFields.forEach((field) => {
        if (!defaultValue[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, defaultValue]);
  const companyType = watch("processing_fee_sharing_status");
  console.log("companyType", companyType);
  useEffect(() => {
    if (companyType === "YES") {
      register("finayo_share", { required: true });
      register("lender_share", { required: true });
      register("processing_fee_amount", { required: true });
      register("processing_fee_type", { required: true });
    } else {
      register("finayo_share");
      register("lender_share");
      register("processing_fee_amount");
      register("processing_fee_type");
    }
  }, [companyType, register]);
  // const renderFormFields = () => {
  //   // const handleSelectChange = (e, id) => {
  //   //   const { value } = e.target;
  //   //   //   setSelectedOption({ ...selectedOption, [id]: value });
  //   //   setSelectedOption((prevState) => {
  //   //     const newState = { ...prevState };
  //   //     newState[id] = value;
  //   //     return newState;
  //   //   });
  //   // };
  //   const handleSelectChange = (e, id) => {
  //     const { value } = e.target;
  //     setSelectedOption((prevState) => {
  //       const newState = { ...prevState };
  //       newState[id] = value;
  //       return newState;
  //     });
  //   };
  //   const formFields = [];

  //   for (let i = 0; i < numForms; i++) {
  //     formFields.push(
  //       <div className={styles.fourinputasc} key={i}>
  //         <div className={styles.searorinp}>
  //           <label
  //             style={{
  //               fontSize: "12px",
  //               color: "#000000",
  //               fontWeight: "bold",
  //             }}
  //           >
  //             Director Name<span className={styles.requ}>*</span>
  //           </label>
  //           <input
  //             className={styles.inpttsc}
  //             type="text"
  //             {...register(`bonusList[${i}].salaryFrom`, {
  //               required: true, // Prevents negative values
  //             })}
  //           />
  //           {/* {errors.bonusList?.[i]?.salaryFrom && (
  //             <span className={styles.error}>
  //               This Field is Required a Positive Value.
  //             </span>
  //           )} */}
  //         </div>
  //         {/* <div className={styles.inputCont}> */}
  //         <div className={styles.searorinp}>
  //           <label
  //             style={{
  //               fontSize: "12px",
  //               color: "#000000",
  //               fontWeight: "bold",
  //             }}
  //           >
  //             Director Number<span className={styles.requ}>*</span>
  //           </label>
  //           <input
  //             className={styles.inpttsc}
  //             type="number"
  //             {...register(`bonusList[${i}].salhkjko`, {
  //               required: true,
  //             })}
  //           />
  //           {/* {errors.bonusList?.[i]?.salaryTo && (
  //             <span className={styles.error}>
  //               This Field is Required a Positive Value.
  //             </span>
  //           )} */}
  //         </div>
  //         {/* <div className={styles.inputCont}> */}
  //         <div className={styles.searorinp}>
  //           <label
  //             style={{
  //               fontSize: "12px",
  //               color: "#000000",
  //               fontWeight: "bold",
  //             }}
  //           >
  //             Director Email<span className={styles.requ}>*</span>
  //           </label>
  //           <input
  //             className={styles.inpttsc}
  //             type="text"
  //             {...register(`bonusList[${i}].salaryTo`, {
  //               required: true,
  //             })}
  //           />
  //           {/* {errors.bonusList?.[i]?.salaryTo && (
  //             <span className={styles.error}>
  //               This Field is Required a Positive Value.
  //             </span>
  //           )} */}
  //         </div>
  //         <div
  //           className={styles.actionCont}
  //           style={{
  //             display: "flex",
  //             width: "50px",
  //             textAlign: "left",
  //             gap: "10px",
  //           }}
  //         >
  //           {numForms > 1 && i !== numForms - 1 && (
  //             <FaMinusSquare
  //               className={styles.plusIcon}
  //               onClick={() => handleMinusForm(i, watch(`bonusList[${i}].id`))}
  //             />
  //           )}
  //           {numForms > 1 && i === numForms - 1 && (
  //             <FaMinusSquare
  //               className={styles.plusIcon}
  //               onClick={() => handleMinusForm(i, watch(`bonusList[${i}].id`))}
  //             />
  //           )}
  //           {(numForms === 1 || i === numForms - 1) && (
  //             <FaPlusSquare
  //               className={styles.plusIcon}
  //               onClick={handleAddForm}
  //             />
  //           )}
  //         </div>
  //       </div>
  //     );
  //   }

  //   return formFields;
  // };
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Name<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("name", { required: true })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Type<span className={styles.requ}>*</span>
              </label>
              <select
                {...register("company_type", { required: true })}
                className={styles.inpttsc}
                style={{ height: "30px" }}
              >
                <option value="">--Select Company Type--</option>
                <option value="PVT LTD">PVT LTD</option>
                <option value="LTD">LTD</option>
              </select>

              {errors.company_type && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Upload Agreement<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("agreement_doc")}
                // accept="image/jpeg, image/png,image/jpg"
                accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.agreement_doc && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                COI Certificate<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("coi_doc")}
                // accept="image/jpeg, image/png,image/jpg"
                accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.coi_doc && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                NBFC Certificate<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("nbfc_certificate")}
                // accept="image/jpeg, image/png,image/jpg"
                accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.nbfc_certificate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Compliance Officer Name<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("complience_officer_name", { required: true })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.complience_officer_name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Compliance Officer Mobile<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("complience_officer_mobile", { required: true })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.complience_officer_mobile && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Compliance Officer Email<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("complience_officer_email", { required: true })}
                type="email"
                className={styles.inpttsc}
              />
              {errors.complience_officer_email && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Lending ROI<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("lending_roi", { required: true })}
                type="number"
                step="0.01"
                className={styles.inpttsc}
              />

              {errors.lending_roi && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Processing Fee Sharing Status
                <span className={styles.requ}>*</span>
              </label>
              <select
                {...register("processing_fee_sharing_status", {
                  required: true,
                })}
                className={styles.inpttsc}
                style={{ height: "30px" }}
              >
                <option value="">--Select Processing Fee Sharing--</option>
                <option value="YES">Yes</option>
                <option value="NO">No</option>
              </select>

              {errors.processing_fee_sharing_status && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          {companyType === "YES" && (
            <div className={styles.fourinputasc}>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Sharing Fee Type<span className={styles.requ}>*</span>
                </label>
                <select
                  {...register("processing_fee_type")}
                  className={styles.inpttsc}
                  style={{ height: "30px" }}
                >
                  <option value="">--Select Sharing Fee Type--</option>
                  <option value="Percentage">Percentage</option>
                  <option value="Flat">Flat</option>
                </select>

                {errors.processing_fee_type && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Finayo(%)<span className={styles.requ}>*</span>
                </label>
                <input
                  {...register("finayo_share")}
                  type="number"
                  step="0.01"
                  className={styles.inpttsc}
                />

                {errors.finayo_share && (
                  <span className={styles.error}>
                    {"This Field is Missing."}
                  </span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  NBFC(%)<span className={styles.requ}>*</span>
                </label>
                <input
                  {...register("lender_share")}
                  type="number"
                  step="0.01"
                  className={styles.inpttsc}
                />

                {errors.lender_share && (
                  <span className={styles.error}>
                    {"This Field is Missing."}
                  </span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Total<span className={styles.requ}>*</span>
                </label>
                <input
                  {...register("processing_fee_amount")}
                  type="number"
                  step="0.01"
                  className={styles.inpttsc}
                />

                {errors.processing_fee_amount && (
                  <span className={styles.error}>
                    {"This Field is Missing."}
                  </span>
                )}
              </div>
            </div>
          )}
          {/* {renderFormFields()} */}
          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
