import React, { useEffect, useState } from "react";
import styles from "./AddChar.module.css";
import { useForm, Controller } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";

const AddCharges = ({ addHandle, defaultValue }) => {
  const [tkn, setTkn] = useState(null);
  console.log("tkn", tkn);
  const defaultCharges = [
    { charge_head: "Interest", charge_value: "", charge_type: "" },
    { charge_head: "Processing Fee", charge_value: "", charge_type: "" },
    { charge_head: "Subvention Amount", charge_value: "", charge_type: "" },
    { charge_head: "FLDG", charge_value: "", charge_type: "" },
    { charge_head: "IOT", charge_value: "", charge_type: "" },
    { charge_head: "Insurance 1st Year", charge_value: "", charge_type: "" },
    { charge_head: "Insurance 2nd Year", charge_value: "", charge_type: "" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    defaultValues: { charges: defaultCharges },
  });

  const fetchPermissions = async () => {
    const response = await api.get(
      `admin/get-active-charges-by-dealer/${defaultValue.id}`
    );
    return response.data;
  };

  const { data: permissionsData, isSuccess } = useQuery({
    queryKey: ["editData", defaultValue],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      console.log("data in map", data);
      if (data?.status === "success") {
        const charges = data?.data?.map((charge) => ({
          charge_head: charge.charge_head,
          charge_type: charge.charge_type || "",
          charge_value: charge.charge_value || "",
        }));
        reset({ charges });
        if (data.data.length > 0 && data.data[0].token_id) {
          setTkn(data.data[0].token_id);
        }
      } else {
        reset({ charges: defaultCharges });
      }
    },
    onError: () => {
      // reset({ charges: defaultCharges });
    },
  });
  useEffect(() => {
    if (defaultValue?.id) {
      reset({ charges: defaultCharges });
    }
  }, [addHandle]);
  // useEffect(() => {
  //   if (defaultValue?.id) {
  //     // Trigger the fetch and reset when `defaultValue` changes
  //     fetchPermissions();
  //   }
  // }, [defaultValue]);

  const onSubmit = (data) => {
    const payload = {
      lender_id: "1",
      // dealer_id: defaultValue?.id,
      charge_details: data.charges.map((charge) => ({
        charge_head: charge.charge_head,
        charge_type: charge.charge_type,
        charge_value: charge.charge_value,
      })),
    };
    if (tkn) {
      payload.token_id = tkn;
    }

    console.log("Payload:", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/store-lender-dealer-charges",
    method: "post",
    invalidateKey: "editData",
    successCallback: () => {
      reset({ charges: defaultCharges });
      addHandle();
    },
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "580px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Product Charges" : "Product Charges"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBottom: "1px solid #red" }}
      >
        <div style={{ textAlign: "left", marginBottom: "12.5px" }}>
          <label
            style={{
              color: "#495057",
              fontWeight: "bold",
              fontSize: "13px",
              marginRight: "5px",
            }}
          >
            Lender :
          </label>
          <select
            style={{
              borderRadius: "4px",
              height: "25px",
              border: "1px solid #ddd",
              width: "200px",
            }}
          >
            <option>--Select Lender--</option>
          </select>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Charges Head
                </th>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Charge Type
                </th>
                <th
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                    padding: "5px",
                  }}
                >
                  Charges Value
                </th>
              </tr>
            </thead>
            <tbody>
              {control._defaultValues.charges.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      style={{ borderRadius: "4px", height: "20px" }}
                      type="text"
                      value={item.charge_head}
                      disabled
                    />
                  </td>
                  <td>
                    <Controller
                      name={`charges[${index}].charge_type`}
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          style={{
                            borderRadius: "4px",
                            height: "25px",
                            border: "1px solid #ddd",
                            width: "150px",
                          }}
                        >
                          <option value="">Select</option>
                          <option value="Fixed">Fixed</option>
                          <option value="percentage">Percentage</option>
                        </select>
                      )}
                    />
                  </td>
                  <td>
                    <Controller
                      name={`charges[${index}].charge_value`}
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          {...field}
                          min="0"
                          style={{
                            borderRadius: "4px",
                            height: "20px",
                            border: "1px solid #ddd",
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddCharges;
