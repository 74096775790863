import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import sampleimg from "./../../../icons/sampleimg.png";
import ZoomImg from "./ZoomImg";
import { Backdrop } from "@mui/material";
const GenralDoc = ({ addHandle, defaultValue, BorrowerId, doc, type }) => {
  const [bimg, setBimg] = useState(false);
  const [zoomImgUrl, setZoomImgUrl] = useState(null);

  const imgHandle = (url) => {
    setZoomImgUrl(url);
    setBimg((val) => !val);
  };
  const getFullUrl = (path) =>
    path ? `https://insta.finayo.tech${path}` : null;

  const renderImages = (imagePaths) => {
    return imagePaths
      .split(",")
      .map((path, index) => (
        <img
          key={index}
          src={getFullUrl(path)}
          style={{ width: "150px", height: "100px" }}
          alt={`Signed Cheque ${index + 1}`}
          onClick={() => imgHandle(getFullUrl(path))}
        />
      ));
  };

  return (
    <form
      style={{ minWidth: "400px", padding: "0 10px " }}
      className={styles.modalcontainerr}
      onClick={(event) => event.stopPropagation()}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={bimg}
        onClick={imgHandle}
      >
        <ZoomImg addHandle={imgHandle} zoomImgUrl={zoomImgUrl} />
      </Backdrop>
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>General Documents </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{
          borderBotom: "1px solid #red",
          display: "flex",
          gap: "10px",
          overflowX: "auto",
          width: "500px",
        }}
      >
        <div className={styles.modalform}>
          {type === "General" && doc.vehicle_image && (
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Vehicle Image
            </p>
          )}
          <div style={{ display: "flex", gap: "10px" }}>
            {" "}
            {type === "General" && doc.vehicle_image && (
              <img
                src={getFullUrl(doc.vehicle_image)}
                style={{ width: "150px", height: "100px" }}
                alt="Vehicle Image"
                onClick={() => imgHandle(getFullUrl(doc?.vehicle_image))}
              />
            )}
          </div>
        </div>
        {type === "General" && doc.insurance && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Insurance
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              <img
                src={getFullUrl(doc.insurance)}
                style={{ width: "150px", height: "100px" }}
                alt="Insurance"
                onClick={() => imgHandle(getFullUrl(doc?.insurance))}
              />
            </div>
          </div>
        )}
        {type === "General" && doc.invoice && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Invoice
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              <img
                src={getFullUrl(doc.invoice)}
                style={{ width: "150px", height: "100px" }}
                alt="Invoice"
                onClick={() => imgHandle(getFullUrl(doc?.invoice))}
              />
            </div>
          </div>
        )}
        {type === "General" && doc.vehicle_signed_cheque && (
          <div className={styles.modalform}>
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Signed Cheque
            </p>

            <div style={{ display: "flex", gap: "10px" }}>
              {" "}
              {/* <img
                src={getFullUrl(doc.vehicle_signed_cheque)}
                style={{ width: "150px", height: "100px" }}
                alt="Signed Cheque"
                onClick={() =>
                  imgHandle(getFullUrl(doc?.vehicle_signed_cheque))
                }
              /> */}
              {renderImages(doc.vehicle_signed_cheque)}
            </div>
          </div>
        )}
        {type === "General" && doc.warrant_document && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Warranty Documents
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.warrant_document)}
                style={{ width: "150px", height: "100px" }}
                alt="Warranty Documents"
                onClick={() => imgHandle(getFullUrl(doc?.warrant_document))}
              />
            </div>
          </div>
        )}
        {type === "General" && doc.vehicle_quotation && (
          <div
            className={styles.modalform}
            style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Vehicle Quotation
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.vehicle_quotation)}
                style={{ width: "150px", height: "100px" }}
                alt="Vehicle Quotation"
                onClick={() => imgHandle(getFullUrl(doc?.vehicle_quotation))}
              />
            </div>
          </div>
        )}
        {type === "General" && doc.rto_tax_receipt && (
          <div
            className={styles.modalform}
            // style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Rto Tax Receipt
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.rto_tax_receipt)}
                style={{ width: "150px", height: "100px" }}
                alt="Rto Tax"
                onClick={() => imgHandle(getFullUrl(doc?.rto_tax_receipt))}
              />
            </div>
          </div>
        )}
        {type === "General" && doc.rto_document && (
          <div
            className={styles.modalform}
            // style={{ marginTop: "10px", padding: "10px" }}
          >
            <p
              style={{
                color: "#495057",
                fontSize: "12px",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              Rto Documents
            </p>
            <div style={{ display: "flex", gap: "10px" }}>
              <img
                src={getFullUrl(doc.rto_document)}
                style={{ width: "150px", height: "100px" }}
                alt="Rto Documents"
                onClick={() => imgHandle(getFullUrl(doc?.rto_document))}
              />
            </div>
          </div>
        )}
      </div>

      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}></div>
        </div>
      </div>
    </form>
  );
};

export default GenralDoc;
