import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const { token, baseURL, baseDoc } = useContext(AuthContext);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  // const handleViewPdf = (pdfUrl) => {
  //   if (pdfUrl) {
  //     window.open(pdfUrl, "_blank");
  //   }
  // };
  const handleViewPdf = (pdfUrl) => {
    if (pdfUrl) {
      const fullUrl = `${baseDoc}${pdfUrl}`;
      window.open(fullUrl, "_blank");
    }
  };
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);

  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };

  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <div>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Name
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.name}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Birth
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.dob}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Mobile Number
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.number}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Alternate Mobile Number
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.alternative_number}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Email
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.email}
              </label>

              <span className={styles.error}>{err.email ? err.email : ""}</span>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                PAN No.
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.pan_number}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.aadhar_number}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Landmark
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.landmark}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                City
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.city}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Pincode
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.pincode}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Address Line 1
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.address_line_1}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Address Line 2
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.address_line_2}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer LOI{" "}
                {existingData && existingData?.dealer_loi && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.dealer_loi)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Uploaded PAN{" "}
                {existingData && existingData?.pan && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.pan)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Bank Statement
                {existingData && existingData?.bank_statement && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.bank_statement)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Trade Certificate{" "}
                {existingData && existingData?.trade_certificate && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.trade_certificate)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                GST Certificate{" "}
                {existingData && existingData?.gst_certificate && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.gst_certificate)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 1
                {existingData && existingData?.security_cheque_1 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_1)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 2
                {existingData && existingData?.security_cheque_2 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_2)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 3
                {existingData && existingData?.security_cheque_3 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_3)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 4
                {existingData && existingData?.security_cheque_4 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData?.security_cheque_4)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Rent/Lease Agreement{" "}
                {existingData && existingData?.rent_or_lease_agreement && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.rent_or_lease_agreement)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Image 1
                {existingData && existingData?.dealer_image_1 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.dealer_image_1)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Image 2
                {existingData && existingData?.dealer_image_2 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.dealer_image_2)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 1
                {existingData && existingData?.showroom_img_1 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_1)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 2
                {existingData && existingData?.showroom_img_2 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_2)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 3
                {existingData && existingData?.showroom_img_3 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_3)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 4
                {existingData && existingData?.showroom_img_4 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_4)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                ITR File
                {existingData && existingData?.itr_file && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.itr_file)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Manufacturing License{" "}
                {existingData && existingData?.manufacturing_license && (
                  <button
                    type="button"
                    onClick={() =>
                      handleViewPdf(existingData.manufacturing_license)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                icat_certificate{" "}
                {existingData && existingData?.icat_certificate && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.icat_certificate)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Utility Bill{" "}
                {existingData && existingData?.utility_bill && (
                  <button
                    type="button"
                    onClick={() => handleViewPdf(existingData.utility_bill)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
            </div>
          </div>
          <div className={styles.oneinputads}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Type
              </label>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {existingData?.company_type}
              </label>
            </div>
          </div>
          {(existingData?.company_type === "PARTNERSHIP" ||
            existingData?.company_type === "PROPRIETORSHIP") && (
            <div className={styles.oneinputads}>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Partnership Deed{" "}
                  {existingData && existingData?.partnership_deed && (
                    <button
                      type="button"
                      onClick={() =>
                        handleViewPdf(existingData.partnership_deed)
                      }
                      className={styles.bbtnvofde}
                    >
                      View
                    </button>
                  )}
                </label>
              </div>
            </div>
          )}
          {existingData?.company_type === "PRIVATE LIMITED" && (
            <div className={styles.fourinputasc}>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Balance Sheet
                  {existingData && existingData?.balance_sheet && (
                    <button
                      type="button"
                      onClick={() => handleViewPdf(existingData.balance_sheet)}
                      className={styles.bbtnvofde}
                    >
                      View
                    </button>
                  )}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  MOA & AOA{" "}
                  {existingData && existingData?.moa_and_aoa && (
                    <button
                      type="button"
                      onClick={() => handleViewPdf(existingData.moa_and_aoa)}
                      className={styles.bbtnvofde}
                    >
                      View
                    </button>
                  )}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Certificate Of Incorporation
                  {existingData &&
                    existingData?.certificate_of_incorporation && (
                      <button
                        type="button"
                        onClick={() =>
                          handleViewPdf(
                            existingData.certificate_of_incorporation
                          )
                        }
                        className={styles.bbtnvofde}
                      >
                        View
                      </button>
                    )}
                </label>
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Factory Address Proof{" "}
                  {existingData && existingData?.factory_address_proof && (
                    <button
                      type="button"
                      onClick={() =>
                        handleViewPdf(existingData.factory_address_proof)
                      }
                      className={styles.bbtnvofde}
                    >
                      View
                    </button>
                  )}
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealerForm;
