import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import SeprateDet from "./SeprateDet";
import { useLocation } from "react-router-dom";
const ViewLoanApp = () => {
  const navigate = useNavigate();
  const addHandle = () => {
    // navigate("/dashboard");
  };
  const location = useLocation();
  const { borrowerId } = location.state || {};
  console.log("borrowerId", borrowerId);
  const fetchData = async () => {
    const response = await api.get(`get-borrower-details/${borrowerId}`);
    return response.data;
  };
  //FIN-0144
  //FIN-0173

  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", borrowerId],
    queryFn: () => fetchData(borrowerId),
    onSuccess: (data) => {
      // data?.allBankDetailsList.sort((a, b) =>
      //   a.bank_name.localeCompare(b.bank_name)
      // );
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Loan Application Details" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            {" "}
            <SeprateDet
              dashboardList={dashboardList?.data || []}
              borrowerId={borrowerId}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewLoanApp;
