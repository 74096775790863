// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// const api = axios.create({
//   baseURL: "https://insta.finayo.tech/api/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
// api.interceptors.request.use((config) => {
//   const navigate = useNavigate();
//   const token = localStorage.getItem("ACCESS_TOKEN");
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   } else {
//     // console.log("redirect to home from api");
//     // window.open(`${BASE_URL}login`);
//     navigate("/b2cAdmin/login");
//   }
//   return config;
// });

// export default api;
import axios from "axios"; // Adjust the import path
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const api = axios.create({
  // baseURL: API_BASE_URL,
  baseURL: "http://13.200.65.87/api/",
  // baseURL: "https://insta.finayo.tech/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("ACCESS_TOKEN"); // Get the token from AuthContext

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("ACCESS_TOKEN");
      // console.log("redirect to home from api");
      // window.open(`${BASE_URL}login`);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
