import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerForm = ({ defaultValue, typeV, peri }) => {
  const { user, token, setUser, setToken, baseURL } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  console.log("peri", peri);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const fetchData = async () => {
    // const response = await api.get(`get-dealer-authorities/84`);
    const response = await api.get(`edit-dealer-authority/${peri.id}`);
    // const response = await api.get(
    //   `get-dealer-authorities/${defaultValue.dealer_id}`
    // );
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("defaultValue", defaultValue);

  const [
    { data: depatmentList },
    { data: userTypeList },
    { data: dealerList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.name);
      setValue("dob", existingData?.dob);
      setValue("mobile", existingData?.mobile);
      setValue("alternative_number", existingData?.alternative_mobile);
      setValue("email", existingData?.email);
      setValue("pan", existingData?.pan);
      setValue("aadhar", existingData?.aadhar);
    }
  }, [existingData, setValue]);
  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();

    formData.append("dealer_id", defaultValue?.id);
    formData.append("name", data.name);
    formData.append("mobile", data.mobile);
    formData.append("alternative_mobile", data.alternative_number);
    formData.append("aadhar", data.aadhar);
    formData.append("pan", data.pan);
    if (peri && peri.id) {
      formData.append("id", peri.id);
    }

    // Append file fields
    const fileFields = ["bank_statement", "itr_file", "photo"];
    const MAX_FILE_SIZE = 3072 * 1024; // 3072 KB
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 3MB: ${fieldNames}.`,
        "error"
      );
      return;
    }

    try {
      const apiEndpoint =
        typeV === "Edit"
          ? `${baseURL}update-dealer-authority` // Use baseURL for update endpoint
          : `${baseURL}store-dealer-authority`;
      // ? `https://insta.finayo.tech/api/update-dealer-authority`
      // : `https://insta.finayo.tech/api/store-dealer-authority`;

      // const response = await axios({
      //   method,
      //   url: apiEndpoint,
      //   data: formData,
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: token,
      //   },
      // });
      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      });

      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/dealers-list");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  //   if (!defaultValue || !defaultValue.name) {
  //     register("trade_certificate", { required: true });
  //   } else {
  //     register("trade_certificate");
  //   }
  useEffect(() => {
    if (existingData) {
      const fileFields = ["bank_statement", "itr_file", "photo"];

      fileFields.forEach((field) => {
        if (!existingData[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, existingData]);
  // useEffect(() => {
  //   // if (!defaultValue || !defaultValue.name) {
  //   //   register("trade_certificate", { required: true });
  //   // } else {
  //   //   register("trade_certificate");
  //   // }
  //   if (existingData) {
  //     if (!existingData.trade_certificate) {
  //       register("trade_certificate", { required: true });
  //     } else {
  //       register("trade_certificate");
  //     }
  //   }
  //   if (existingData) {
  //     if (!existingData.bank_statement) {
  //       register("bank_statement", { required: true });
  //     } else {
  //       register("bank_statement");
  //     }
  //   }
  //   register("bank_statement", { required: true });
  //   register("gst_certificate", { required: true });
  //   register("security_cheque_1", { required: true });
  //   register("security_cheque_2", { required: true });
  //   register("security_cheque_3", { required: true });
  //   register("security_cheque_4", { required: true });
  //   register("rent_agreement", { required: true });
  //   register("dealer_image_1", { required: true });
  //   register("dealer_image_2", { required: true });
  //   register("showroom_img_1", { required: true });
  //   register("showroom_img_2", { required: true });
  //   register("showroom_img_3", { required: true });
  //   register("showroom_img_4", { required: true });
  //   register("itr_file", { required: true });
  //   register("balance_sheet", { required: true });
  // }, [register, defaultValue]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            {/* <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer <span className={styles.requ}>*</span>
              </label>
              <select
                {...register("dealer_id", { required: true })}
                className={styles.mdselecttt}
              >
                <option value="">--Select Dealer--</option>
                {dealerList?.data?.map((val) => (
                  <option key={val.id} value={val.id}>
                    {val.name}
                  </option>
                ))}
              </select>
              {errors.dealer_id && (
                <span className={styles.error}>This Field is Required.</span>
              )}
            </div> */}

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Name<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("name", { required: true })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Mobile Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("mobile", {
                  required: true,
                  minLength: {
                    value: 10,
                    message: "Please Enter 10 digits.",
                  },
                })}
              />
              {errors.mobile && (
                <span className={styles.error}>
                  {errors.mobile.message || "This Field is Missing."}
                </span>
              )}
              <span className={styles.error}>
                {err.mobile ? err.mobile : ""}
              </span>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Alternate Mobile Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("alternative_number", {
                  required: true,
                  minLength: {
                    value: 10,
                    message: "Please Enter 10 digits.",
                  },
                })}
              />

              {errors.alternative_number && (
                <span className={styles.error}>
                  {errors.alternative_number.message ||
                    "This Field is Required."}
                </span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar No<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("aadhar", {
                  required: true,
                  minLength: {
                    value: 12,
                    message: "Invalid Aadharr No.",
                  },
                })}
                type="number"
                className={styles.inpttsc}
              />
              {errors.aadhar && (
                <span className={styles.error}>{"This Field is Misiing"}</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                PAN<span className={styles.requ}>*</span>
              </label>
              <input
                {...register("pan", {
                  required: true,
                  // minLength: {
                  //   value: 16,
                  //   message: "Too Short",
                  // },
                })}
                type="text"
                className={styles.inpttsc}
              />
              {errors.pan && (
                <span className={styles.error}>{"This Field is Misiing"}</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Bank Statement<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("bank_statement")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.bank_statement && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Balance Sheet<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("itr_file")}
                // accept="image/jpeg, image/png,image/jpg"
                accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.itr_file && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                ITR File<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("photo")}
                accept="image/jpeg, image/png,image/jpg"
                // onChange={handleFileChange}
                // accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.photo && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              {typeV === "Edit" ? "Update" : "Submit"}
              {/* {defaultValue ? "Update" : "Submit"} */}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
