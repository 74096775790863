import React, { useEffect, useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import Table from "./Table";
import PaginationComponent from "../../Common/PaginationComponent";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../Common/api";
import Loader from "../../Common/Loader";
const LspSetting = () => {
  const [page, setPage] = useState(1);
  const [numberOfRecords, setNumberOfRecords] = useState(10);
  const [add, setAdd] = useState(false);
  const [totalPage, setTotalPage] = useState(2);
  const [searchData, setSearchData] = useState(null);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const searchDataHandler = (data) => {
    setSearchData(data);
    setPage(1);
  };
  const fetchData = async ({ page, numberOfRecords, searchData }) => {
    const payload = { page, number_of_records: numberOfRecords };

    if (searchData) {
      payload.bankId = searchData.bankId || "";
    }

    const response = await api.get("get-borrower-list", { params: payload });
    return response.data;
  };

  const DetailsResponse = useQuery({
    queryKey: ["borrowerList", page, numberOfRecords, searchData],
    queryFn: () => fetchData({ page, numberOfRecords, searchData }),
    onSuccess: (data) => {
      //   setTotalPage(data.last_page);
    },
  });

  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  // console.log("dashboardList", dashboardList);
  const navigate = useNavigate();
  const addHandle = () => {
    navigate("/b2cAdmin/add-borrower");
  };
  const handleRecordsChange = (event) => {
    setNumberOfRecords(event.target.value);
  };

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="LSP Settings" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            <div className={classes.tableContai}>
              {isLoading || isFetching ? (
                <Loader />
              ) : (
                <Table
                  rowData={dashboardList?.data || []}
                  // page={page}
                  numberOfRecords={numberOfRecords}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LspSetting;
