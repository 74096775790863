import React, { useState } from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
import { Backdrop } from "@mui/material";
import CoModal from "./CoModal";
const CoApplicantDetails = ({ Detailsb }) => {
  console.log("Detailsb", Detailsb);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  console.log("Detailsb", Detailsb);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <CoModal
          addHandle={addHandle}
          doc={Detailsb?.documents || {}}
          type="coapplicant"
        />
      </Backdrop>
      <div style={{ textAlign: "right" }}>
        <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
          }}
          onClick={addHandle}
        >
          View Document
        </button>
      </div>
      <div className={styles.brwdettcon}>
        <div className={styles.wholetxtcon}>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Co-Applicant Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Gender</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.gender || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Mobile No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Date Of Birth</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.dob_date || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>PAN no.</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.pan || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Aadhar No.</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.aadhar || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Father's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_details?.father_name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.occupation_type || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Occupation</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.current_occupation || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Email Id</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.address_details?.email || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.monthly_earning || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Total Income</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.total_income || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>City</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.address_details?.city || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>State</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.address_details?.full_address
                  ?.state || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Country</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.address_details?.full_address
                  ?.country || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Cibil</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.credit_details?.coappli_cibil_score}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Postal</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.address_details?.full_address
                  ?.pc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Resident Type</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.resident_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.current_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Years Living</p>
              <p className={styles.pbtfrtxt}> </p>
            </div>
          </div>

          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Permanent Address</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.co_applicant_address?.permanent_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Single Line Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.address_details
                  ?.single_line_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Source Income Area</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.source_income_area || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Vehicle Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.co_applicant_address?.vehicle_type || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoApplicantDetails;
