import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { style } from "@mui/system";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";

const AddModal = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  useEffect(() => {
    if (defaultValue) {
      setValue("language", defaultValue?.status);
    }
  }, [defaultValue, setValue]);

  const onSubmit = async (data) => {
    let payload;
    payload = {
      status: data.language,
    };
    console.log("updatedData", payload);
    updateVpnMutation.mutate(payload);
  };

  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `update-marital-status/${defaultValue.id}`
      : "store-marital-status",
    method: defaultValue ? "put" : "post",
    invalidateKey: "bankDetailsList",
    successCallback: () => {
      reset();
      addHandle();
    },
  });
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>
          {defaultValue ? "Edit Marital Status" : "Add Marital Status"}
        </h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{ borderBotom: "1px solid #red" }}
      >
        <div className={styles.modalform}>
          <label
            style={{
              marginBottom: "0.5rem",
              color: "#494646",
              fontSize: "13px",
            }}
          >
            Marital Status
          </label>
          <input
            {...register("language", { required: true })}
            type="text"
            className={styles.mdlinput}
          />
          {errors.language && (
            <span className={styles.error}>This Field is Missing.</span>
          )}
        </div>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddModal;
