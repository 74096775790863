import "./App.css";
import { RouterProvider } from "react-router-dom";
import React, { Suspense } from "react";
import routes from "./routes/routes";
import { AuthProvider } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastProvder";
import LoginPage from "./components/Login/Login";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./reactQueryClient"; //
// const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ToastProvider>
          <div className="App">
            <Suspense fallback={<div>Loading...</div>}>
              <RouterProvider router={routes} />
            </Suspense>
          </div>
        </ToastProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
