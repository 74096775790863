import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
const DealerForm = ({ defaultValue }) => {
  const [add, setAdd] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();
  const { token, baseURL } = useContext(AuthContext);
  const fetchData = async () => {
    const response = await api.get(`edit-dealer/${defaultValue.id}`);
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList"],
    queryFn: () => fetchData(),
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  const { isLoading, isFetching, data: dashboardList } = DetailsResponse;
  console.log("existingData", existingData);
  const companyType = watch("company_type");
  console.log("companyType", companyType);
  useEffect(() => {
    if (existingData) {
      setValue("name", existingData?.name);
      setValue("dob", existingData?.dob);
      setValue("mobile", existingData?.mobile);
      setValue("alternative_number", existingData?.alternative_number);
      setValue("email", existingData?.email);
      setValue("pan_number", existingData?.pan_number);
      setValue("aadhar_number", existingData?.aadhar_number);
      setValue("landmark", existingData?.landmark);
      setValue("city", existingData?.city);
      setValue("pincode", existingData?.pincode);
      setValue("address_line_1", existingData?.address_line_1);
      setValue("address_line_2", existingData?.address_line_2);
      setValue("company_type", existingData?.company_type);
      // setValue("dealer_loi", existingData?.dealer_loi);
    }
  }, [existingData, setValue]);

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append non-file fields
    formData.append("name", data.name);
    formData.append("dob", data.dob);
    formData.append("mobile", data.mobile);
    formData.append("alternative_number", data.alternative_number);
    formData.append("email", data.email);
    formData.append("pan_number", data.pan_number);
    formData.append("aadhar_number", data.aadhar_number);
    formData.append("landmark", data.landmark);
    formData.append("city", data.city);
    formData.append("pincode", data.pincode);
    formData.append("address_line_1", data.address_line_1);
    formData.append("address_line_2", data.address_line_2);
    // formData.append("dealer_loi", data.dealer_loi);
    formData.append("company_type", data.company_type);
    if (defaultValue && defaultValue.id) {
      formData.append("id", defaultValue.id);
    }
    // Append file fields
    const fileFields = [
      "bank_statement",
      "trade_certificate",
      "gst_certificate",
      "security_cheque_1",
      "security_cheque_2",
      "security_cheque_3",
      "security_cheque_4",
      "rent_or_lease_agreement",
      // "rent_or_lease_agreement",
      "dealer_image_1",
      "dealer_image_2",
      "showroom_img_1",
      "showroom_img_2",
      "showroom_img_3",
      "showroom_img_4",
      "itr_file",
      "pan",
      "aadhar",
      "balance_sheet",
      "icat_certificate",
      "utility_bill",
      "dealer_loi",
      "partnership_deed",
      "manufacturing_license",
      "moa_and_aoa",
      "certificate_of_incorporation",
      "factory_address_proof",
    ];

    const MAX_FILE_SIZE = 3072 * 1024; // 3072 KB

    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 3MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    // fileFields.forEach((field) => {
    //   if (data[field] && data[field].length > 0) {
    //     // If new file is uploaded, append it
    //     formData.append(field, data[field][0]);
    //   } else if (defaultValue && defaultValue[field]) {
    //     // If no new file is uploaded, append the existing file value
    //     formData.append(field, defaultValue[field]);
    //   }
    // });

    try {
      // const response = await axios.post(
      //   "https://insta.finayo.tech/api/store-dealer",
      //   formData,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      const apiEndpoint = defaultValue
        ? `${baseURL}update-dealer`
        : `${baseURL}store-dealer`;
      // ? `https://insta.finayo.tech/api/update-dealer`
      // : "https://insta.finayo.tech/api/store-dealer";

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      const { status, message } = response.data;
      showToast(message, status);
      navigate("/dealers-list");
      console.log("Form submitted successfully:", response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
      const { message, errors } = error.response.data;
      console.log("message", message);
      setErr(message);
      showToast(message, "error");
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  //   if (!defaultValue || !defaultValue.name) {
  //     register("trade_certificate", { required: true });
  //   } else {
  //     register("trade_certificate");
  //   }
  useEffect(() => {
    if (existingData) {
      if (!existingData.trade_certificate) {
        register("trade_certificate", { required: true });
      } else {
        register("trade_certificate");
      }

      const fileFields = [
        "bank_statement",
        "trade_certificate",
        "gst_certificate",
        "security_cheque_1",
        "security_cheque_2",
        "security_cheque_3",
        "security_cheque_4",
        "rent_or_lease_agreement",
        "dealer_image_1",
        "dealer_image_2",
        "showroom_img_1",
        "showroom_img_2",
        "showroom_img_3",
        "showroom_img_4",
        "itr_file",
        "dealer_loi",
        // "balance_sheet",
        "icat_certificate",
        "utility_bill",
        // "partnership_deed",
        "manufacturing_license",
        // "moa_and_aoa",
        // "certificate_of_incorporation",
        // "factory_address_proof",
        "pan",
        "aadhar",
      ];

      fileFields.forEach((field) => {
        if (!existingData[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, existingData]);
  useEffect(() => {
    if (companyType === "PARTNERSHIP" || companyType === "PROPRIETORSHIP") {
      register("partnership_deed", { required: true });
    } else {
      register("partnership_deed");
    }

    if (companyType === "PRIVATE LIMITED") {
      register("balance_sheet", { required: true });
      register("moa_and_aoa", { required: true });
      register("certificate_of_incorporation", { required: true });
      register("factory_address_proof", { required: true });
    } else {
      register("balance_sheet");
      register("moa_and_aoa");
      register("certificate_of_incorporation");
      register("factory_address_proof");
    }
  }, [companyType, register]);
  // useEffect(() => {
  //   // if (!defaultValue || !defaultValue.name) {
  //   //   register("trade_certificate", { required: true });
  //   // } else {
  //   //   register("trade_certificate");
  //   // }
  //   if (existingData) {
  //     if (!existingData.trade_certificate) {
  //       register("trade_certificate", { required: true });
  //     } else {
  //       register("trade_certificate");
  //     }
  //   }
  //   if (existingData) {
  //     if (!existingData.bank_statement) {
  //       register("bank_statement", { required: true });
  //     } else {
  //       register("bank_statement");
  //     }
  //   }
  //   register("bank_statement", { required: true });
  //   register("gst_certificate", { required: true });
  //   register("security_cheque_1", { required: true });
  //   register("security_cheque_2", { required: true });
  //   register("security_cheque_3", { required: true });
  //   register("security_cheque_4", { required: true });
  //   register("rent_agreement", { required: true });
  //   register("dealer_image_1", { required: true });
  //   register("dealer_image_2", { required: true });
  //   register("showroom_img_1", { required: true });
  //   register("showroom_img_2", { required: true });
  //   register("showroom_img_3", { required: true });
  //   register("showroom_img_4", { required: true });
  //   register("itr_file", { required: true });
  //   register("balance_sheet", { required: true });
  // }, [register, defaultValue]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Name<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Birth<span className={styles.requ}>*</span>
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("dob", { required: true })}
              />
              {errors.dob && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Mobile Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("mobile", {
                  required: true,
                  minLength: {
                    value: 10,
                    message: "Please Enter 10 digits.",
                  },
                })}
              />
              {errors.mobile && (
                <span className={styles.error}>
                  {errors.mobile.message || "This Field is Missing."}
                </span>
              )}
              <span className={styles.error}>
                {err.mobile ? err.mobile : ""}
              </span>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Alternate Mobile Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("alternative_number", {
                  required: true,
                  minLength: {
                    value: 10,
                    message: "Please Enter 10 digits.",
                  },
                })}
              />

              {errors.alternative_number && (
                <span className={styles.error}>
                  {errors.alternative_number.message ||
                    "This Field is Required."}
                </span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Email<span className={styles.requ}>*</span>
              </label>
              <input
                type="email"
                className={styles.inpttsc}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
              <span className={styles.error}>{err.email ? err.email : ""}</span>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                PAN No.<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                onInput={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                {...register("pan_number", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9]{10}$/,
                    message: "Please Enter Valid PAN Number",
                  },
                })}
              />
              {errors.pan_number && (
                <span className={styles.error}>
                  {errors.pan_number.message || "This Field is Missing."}
                </span>
              )}
              {/* {errors.pan_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )} */}
              <span className={styles.error}>
                {err.pan_number ? err.pan_number : ""}
              </span>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar Number<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("aadhar_number", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9]{12}$/,
                    message: "Please Enter Aadhar Number",
                  },
                })}
              />
              {/* {errors.aadhar_number && (
                <span className={styles.error}>This Field is Missing.</span>
              )} */}
              {errors.aadhar_number && (
                <span className={styles.error}>
                  {errors.aadhar_number.message || "This Field is Missing."}
                </span>
              )}
              <span className={styles.error}>
                {err.aadhar_number ? err.aadhar_number : ""}
              </span>
              {/* {errors.aadhar_number && (
                <span className={styles.error}>{"This Field is Missing."}</span>
              )} */}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Landmark<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("landmark", { required: true })}
              />
              {errors.landmark && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                City<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("city", { required: true })}
              />
              {errors.city && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Pincode<span className={styles.requ}>*</span>
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("pincode", {
                  required: true,
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Please Enter Valid Pincode",
                  },
                })}
              />
              {errors.pincode && (
                <span className={styles.error}>
                  {errors.pincode.message || "This Field is Missing."}
                </span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Address Line 1<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("address_line_1", { required: true })}
              />
              {errors.address_line_1 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Address Line 2<span className={styles.requ}>*</span>
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("address_line_2", { required: true })}
              />
              {errors.address_line_2 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer LOI<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("dealer_loi")}
                // accept="application/pdf"
                //    onChange={handleChange("trade_certificate")}
              />
              {/* <input
                type="text"
                className={styles.inpttsc}
                {...register("dealer_loi", { required: true })}
              /> */}
              {errors.dealer_loi && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Upload PAN <span className={styles.requ}>*</span>
              </label>
              <input
                className={styles.inpttsc}
                {...register("pan")}
                type="file"
                // accept="application/pdf"
                // onChange={handleChange("bank_statement")}
                // onChange={(e) => {
                //   fileChangeHandler(e);
                // }}
              />

              {errors.pan && (
                <span className={styles.error}>
                  {errors.bank_statement.message || "This Field is Missing."}
                </span>
              )}
              {/* {errors.bank_statement && (
                <span className={styles.error}>This Field is Required.</span>
              )} */}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Bank Statement<span className={styles.requ}>*</span>
              </label>
              <input
                className={styles.inpttsc}
                {...register("bank_statement")}
                type="file"
                accept="application/pdf"
                // onChange={handleChange("bank_statement")}
                // onChange={(e) => {
                //   fileChangeHandler(e);
                // }}
              />

              {errors.bank_statement && (
                <span className={styles.error}>
                  {errors.bank_statement.message || "This Field is Missing."}
                </span>
              )}
              {/* {errors.bank_statement && (
                <span className={styles.error}>This Field is Required.</span>
              )} */}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Trade Certificate<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("trade_certificate")}
                accept="application/pdf"
                //    onChange={handleChange("trade_certificate")}
              />
              {errors.trade_certificate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                GST Certificate<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("gst_certificate")}
                // onChange={handleChange("gst_certificate")}
                accept="application/pdf"
                //onChange={handleChange("gst_certificate")}
              />
              {errors.gst_certificate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 1<span className={styles.requ}>*</span>
                {existingData && existingData?.security_cheque_1 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_1)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("security_cheque_1")}
                accept="image/jpeg, image/png,image/jpg"
                //  onChange={handleChange("security_cheque_1")}
              />
              {errors.security_cheque_1 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 2<span className={styles.requ}>*</span>
                {existingData && existingData?.security_cheque_2 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_2)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("security_cheque_2")}
                accept="image/jpeg, image/png,image/jpg"
                // onChange={handleChange("security_cheque_2")}
              />
              {errors.security_cheque_2 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 3<span className={styles.requ}>*</span>
                {existingData && existingData?.security_cheque_3 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData.security_cheque_3)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("security_cheque_3")}
                accept="image/jpeg, image/png,image/jpg"
                // onChange={handleChange("security_cheque_3")}
              />
              {errors.security_cheque_3 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Security Cheque 4<span className={styles.requ}>*</span>
                {existingData && existingData?.security_cheque_4 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleEditRow(existingData?.security_cheque_4)
                    }
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("security_cheque_4")}
                accept="image/jpeg, image/png,image/jpg"
                //onChange={handleChange("security_cheque_4")}
              />
              {errors.security_cheque_4 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Rent/Lease Agreement<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("rent_or_lease_agreement")}
                accept="application/pdf"
                //  onChange={handleChange("rent_agreement")}
              />
              {errors.rent_or_lease_agreement && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Image 1<span className={styles.requ}>*</span>
                {existingData && existingData?.dealer_image_1 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.dealer_image_1)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("dealer_image_1")}
                accept="image/jpeg, image/png,image/jpg"
                //  onChange={handleChange("dealer_image_1")}
              />
              {errors.dealer_image_1 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Dealer Image 2<span className={styles.requ}>*</span>
                {existingData && existingData?.dealer_image_2 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.dealer_image_2)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("dealer_image_2")}
                accept="image/jpeg, image/png,image/jpg"
                //  onChange={handleChange("dealer_image_2")}
              />
              {errors.dealer_image_2 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 1<span className={styles.requ}>*</span>
                {existingData && existingData?.showroom_img_1 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_1)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("showroom_img_1")}
                accept="image/jpeg, image/png,image/jpg"
                //  onChange={handleChange("showroom_img_1")}
              />
              {errors.showroom_img_1 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 2<span className={styles.requ}>*</span>
                {existingData && existingData?.showroom_img_2 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_2)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("showroom_img_2")}
                accept="image/jpeg, image/png,image/jpg"
                //   onChange={handleChange("showroom_img_2")}
              />
              {errors.showroom_img_2 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 3<span className={styles.requ}>*</span>
                {existingData && existingData?.showroom_img_3 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_3)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("showroom_img_3")}
                accept="image/jpeg, image/png,image/jpg"
                //    onChange={handleChange("showroom_img_3")}
              />
              {errors.showroom_img_3 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Showroom Image 4<span className={styles.requ}>*</span>
                {existingData && existingData?.showroom_img_4 && (
                  <button
                    type="button"
                    onClick={() => handleEditRow(existingData.showroom_img_4)}
                    className={styles.bbtnvofde}
                  >
                    View
                  </button>
                )}
              </label>
              <input
                type="file"
                accept="image/jpeg, image/png,image/jpg"
                // onChange={handleChange("showroom_img_4")}
                className={styles.inpttsc}
                {...register("showroom_img_4")}
              />
              {errors.showroom_img_4 && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                ITR File<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("itr_file")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.itr_file && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Manufacturing License<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("manufacturing_license")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.manufacturing_license && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                icat_certificate<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("icat_certificate")}
                // onChange={handleFileChange}
                accept="application/pdf"
                //   onChange={handleChange("itr_file")}
              />
              {errors.icat_certificate && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Utility Bill<span className={styles.requ}>*</span>
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("utility_bill")}
                // accept="image/jpeg, image/png,image/jpg"
                // accept="application/pdf"
                // onChange={fileChangeHandler}
                //  onChange={handleChange("balance_sheet")}
              />
              {errors.utility_bill && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          <div className={styles.oneinputads}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Company Type<span className={styles.requ}>*</span>
              </label>
              <select
                style={{ height: "30px" }}
                className={styles.inpttsc}
                {...register("company_type", {
                  required: true,
                })}
              >
                <option value="">--Select Company Type--</option>
                <option value="PARTNERSHIP">PARTNERSHIP</option>
                <option value="PROPRIETORSHIP">PROPRIETORSHIP</option>
                <option value="PRIVATE LIMITED">PRIVATE LIMITED</option>
              </select>
              {errors.company_type && (
                <span className={styles.error}>This Field is Missing.</span>
              )}
            </div>
          </div>
          {(companyType === "PARTNERSHIP" ||
            companyType === "PROPRIETORSHIP") && (
            <div className={styles.oneinputads}>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Partnership Deed<span className={styles.requ}>*</span>
                </label>
                <input
                  type="file"
                  className={styles.inpttsc}
                  {...register("partnership_deed")}
                  // accept="image/jpeg, image/png,image/jpg"
                  // accept="application/pdf"
                  // onChange={fileChangeHandler}
                  //  onChange={handleChange("balance_sheet")}
                />
                {errors.partnership_deed && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            </div>
          )}
          {companyType === "PRIVATE LIMITED" && (
            <div className={styles.fourinputasc}>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Balance Sheet<span className={styles.requ}>*</span>
                </label>
                <input
                  type="file"
                  className={styles.inpttsc}
                  {...register("balance_sheet")}
                  // onChange={handleFileChange}
                  accept="application/pdf"
                  //   onChange={handleChange("itr_file")}
                />
                {errors.balance_sheet && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  MOA & AOA<span className={styles.requ}>*</span>
                </label>
                <input
                  type="file"
                  className={styles.inpttsc}
                  {...register("moa_and_aoa")}
                  // onChange={handleFileChange}
                  // accept="application/pdf"
                  //   onChange={handleChange("itr_file")}
                />
                {errors.moa_and_aoa && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Certificate Of Incorporation
                  <span className={styles.requ}>*</span>
                </label>
                <input
                  type="file"
                  className={styles.inpttsc}
                  {...register("certificate_of_incorporation")}
                  // onChange={handleFileChange}
                  accept="application/pdf"
                  //   onChange={handleChange("itr_file")}
                />
                {errors.certificate_of_incorporation && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={styles.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Factory Address Proof<span className={styles.requ}>*</span>
                </label>
                <input
                  type="file"
                  className={styles.inpttsc}
                  {...register("factory_address_proof", { required: true })}
                  // accept="image/jpeg, image/png,image/jpg"
                  // accept="application/pdf"
                  // onChange={fileChangeHandler}
                  //  onChange={handleChange("balance_sheet")}
                />
                {errors.factory_address_proof && (
                  <span className={styles.error}>This Field is Missing.</span>
                )}
              </div>
            </div>
          )}
          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              {defaultValue ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
