import React, { useEffect } from "react";
import ReactQuill from "react-quill";
// import EditorToolbar, { modules, formats } from "./EditorToolbar";
import Tolbar, { modules, formats } from "./Tolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

export const RichTextEditor = ({ onTextChange, value }) => {
  const [state, setState] = React.useState({ value: value || null });

  const handleChange = (value) => {
    setState({ value });
    onTextChange(value);
    // console.log("value", value);
  };

  useEffect(() => {
    setState({ value });
  }, [value]);

  const customEditorStyle = {
    minHeight: "100px",
    /* Add more custom styles here */
  };
  return (
    <div className="text-editor">
      {/* <EditorToolbar /> */}
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={handleChange}
        // placeholder={"Write something awesome..."}
        // modules={modules}
        // formats={formats}
        editorStyle={customEditorStyle}
        style={{ color: "black" }}
      />
    </div>
  );
};

export default RichTextEditor;
