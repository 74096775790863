// import { useToast } from "../../store/ToastProvider";
// import api from "./api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../Common/api";
import { useToast } from "../../context/ToastProvder";

const useCustomMutation = ({
  url,
  invalidateKey,
  successCallback,
  method,
  errorCallback,
  setErr,
}) => {
  const queryClient = useQueryClient();
  const { showToast } = useToast();

  const createMutation = useMutation(
    async (data) => {
      const updatedPayload = {
        ...data,
      };

      const response = await api[method](url, updatedPayload);
      console.log("API Response:", response.data);
      return response.data;
    },
    {
      onSuccess: (data) => {
        console.log("Mutation Response Data:", data); // Log the response data on success
        const { status, message } = data;

        if (status === "success") {
          if (successCallback) successCallback();
          queryClient.invalidateQueries(invalidateKey);
          showToast(message, status);
        }
      },
      // onError: (error) => {
      //   console.log("Mutation Error:", error); // Log error details
      //   const { message, errors } = error.response.data;
      //   showToast(message, "error");
      // },
      onError: (error) => {
        console.log("Mutation Error:", error);
        const { message, data: errorDetails } = error.response.data;
        console.log("errorDetails", error.response.data);
        setErr(errorDetails);
        showToast(message, "error");
        if (errorCallback) {
          errorCallback(errorDetails);
        }

        // Return the error details to be used in the component
        return errorDetails;
      },
    }
  );

  return createMutation;
};

export default useCustomMutation;
