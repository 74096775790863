import React from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";
// import api from "../../../util/api";
const BorrowerForm = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, setValue, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    onSearch(data);
  };
  const clearHandler = () => {
    reset();
    onSearch(null);
  };
  // const [employeeCode, setEmployeeCode] = useState("");
  // const [employeeName, setEmployeeName] = useState("");
  // const [subCompany, setSubCompany] = useState("");
  // const [branch, setBranch] = useState("");
  // const [department, setDepartment] = useState("");
  // const [subDepartment, setSubDepartment] = useState("");
  // const [location, setLocation] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [employeeType, setEmployeeType] = useState("");
  // const [employeeCategory, setEmployeeCategory] = useState("");
  // const [biometricID, setBiometricID] = useState("");
  // const [management, setManagement] = useState("");
  // const [approvalRequired, setApprovalRequired] = useState("");
  // const [joiningDate, setJoiningDate] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     employeeCode,
  //     employeeName,
  //     subCompany,
  //     branch,
  //     department,
  //     subDepartment,
  //     location,
  //     designation,
  //     employeeType,
  //     employeeCategory,
  //     biometricID,
  //     management,
  //     approvalRequired,
  //     joiningDate,
  //   };
  //   console.log(payload);
  // };

  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower
              </label>
              <select className={styles.selctasc} {...register("employeeType")}>
                <option value="" hidden>
                  ---Select---
                </option>
              </select>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan
              </label>
              <select className={styles.selctasc} {...register("employeeType")}>
                <option value="" hidden>
                  ---Select---
                </option>
              </select>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Applation Data
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan Status
              </label>
              <select className={styles.selctasc} {...register("employeeType")}>
                <option value="" hidden>
                  ---Select---
                </option>
              </select>
            </div>
          </div>

          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Birth
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan Amount
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Product
              </label>
              <select className={styles.selctasc} {...register("employeeType")}>
                <option value="" hidden>
                  ---Select---
                </option>
              </select>
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Tenure
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
          </div>

          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Paid Amount
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Due Amount
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BorrowerForm;
