import React, { useEffect, useState } from "react";
import styles from "./AddChar.module.css";
import { useForm } from "react-hook-form";
import close from "./../../../icons/close.png";
import useCustomMutation from "../../CustomHook/useCustomMutation";
import { useQueries } from "@tanstack/react-query";
import api from "../../Common/api";
const ViewCharges = ({ addHandle, defaultValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    setValue,
  } = useForm();
  const password = watch("password");
  const [err, setErr] = useState({});
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue.name);
      setValue("mobile", defaultValue.mobile);
      setValue("email", defaultValue.email);
      setValue("department", defaultValue?.department_id);
      setValue("user_type", defaultValue.user_type_id);
    }
  }, [defaultValue, setValue]);
  const [{ data: depatmentList }, { data: userTypeList }] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });
  const onSubmit = async (data) => {
    let payload;
    if (defaultValue) {
      payload = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        department: data.department,
        user_type: data.user_type,
      };
    } else {
      payload = {
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        department: data.department,
        user_type: data.user_type,
      };
    }
    // console.log("updatedData", payload);
    // updateVpnMutation.mutate(payload);
  };
  // const updateVpnMutation = useCustomMutation({
  //   url: defaultValue ? `update-user/${defaultValue.id}` : "admin/register",
  //   method: defaultValue ? "put" : "post",
  //   invalidateKey: "userlist",
  //   successCallback: () => {
  //     reset();
  //     // addHandle();
  //   },
  // });
  const updateVpnMutation = useCustomMutation({
    url: defaultValue
      ? `admin/update-user/${defaultValue.id}`
      : "admin/register",
    method: defaultValue ? "put" : "post",
    invalidateKey: "userlist",
    successCallback: () => {
      reset();
      addHandle();
    },
    setErr: setErr,
  });
  console.log("Err", err);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.modalcontainer}
      style={{ width: "520px" }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={styles.modalHeader}>
        <h3 className={styles.modalheading}>Product Charges Details</h3>
        <img
          onClick={addHandle}
          src={close}
          style={{
            width: "11px",
            position: "relative",
            top: "3px",
            cursor: "pointer",
          }}
        />
      </div>
      <div
        className={styles.modalbody}
        style={{
          borderBotom: "1px solid #red",
          height: "300px",
          overflowY: "scroll",
        }}
      >
        <table>
          <thead>
            <tr>
              <th
                style={{
                  color: "#495057",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Lender Name
              </th>
              <th
                style={{
                  color: "#495057",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Charges Head
              </th>
              <th
                style={{
                  color: "#495057",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Charge Type
              </th>
              <th
                style={{
                  color: "#495057",
                  fontWeight: "bold",
                  padding: "5px",
                }}
              >
                Charges Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ padding: "10px 15px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p></p>
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              type="button"
              className={styles.closebtn}
              onClick={addHandle}
            >
              Close
            </button>
            {/* <button type="submit" className={styles.Subbtn}>
              Submit
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default ViewCharges;
