import React from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useState } from "react";
// import api from "../../../util/api";
const BorrowerForm = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch, setValue, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    onSearch(data);
  };
  const clearHandler = () => {
    reset();
    onSearch(null);
  };
  // const [employeeCode, setEmployeeCode] = useState("");
  // const [employeeName, setEmployeeName] = useState("");
  // const [subCompany, setSubCompany] = useState("");
  // const [branch, setBranch] = useState("");
  // const [department, setDepartment] = useState("");
  // const [subDepartment, setSubDepartment] = useState("");
  // const [location, setLocation] = useState("");
  // const [designation, setDesignation] = useState("");
  // const [employeeType, setEmployeeType] = useState("");
  // const [employeeCategory, setEmployeeCategory] = useState("");
  // const [biometricID, setBiometricID] = useState("");
  // const [management, setManagement] = useState("");
  // const [approvalRequired, setApprovalRequired] = useState("");
  // const [joiningDate, setJoiningDate] = useState("");

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const payload = {
  //     employeeCode,
  //     employeeName,
  //     subCompany,
  //     branch,
  //     department,
  //     subDepartment,
  //     location,
  //     designation,
  //     employeeType,
  //     employeeCategory,
  //     biometricID,
  //     management,
  //     approvalRequired,
  //     joiningDate,
  //   };
  //   console.log(payload);
  // };

  return (
    <div style={{ marginBottom: "15px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.searchformasc}>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower ID
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower Name
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Borrower Avater/Image
              </label>
              <input
                type="file"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Mobile No
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
          </div>

          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Date Of Birth
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Marital Status
              </label>
              <select className={styles.selctasc} {...register("employeeType")}>
                <option value="" hidden>
                  ---Select---
                </option>
              </select>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Spouse's Name
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Father's Name
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
          </div>

          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Gender
              </label>
              <select
                {...register("subDepartmentId")}
                className={styles.selctasc}
              >
                <option className={styles.optionnsel} value="">
                  ---Select---
                </option>
              </select>
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Alternate Number
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Email Id
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Pan No
              </label>
              <input
                {...register("employeeDoj")}
                type="text"
                className={styles.inpttsc}
              />
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar No
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Driving License No
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Cibil
              </label>
              <input
                type="text"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Postal
              </label>
              <input
                {...register("employeeDoj")}
                type="text"
                className={styles.inpttsc}
              />
            </div>
          </div>
          <div className={styles.fourinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Aadhar No
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan Amount
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Down Payment
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("biometricAttendanceId")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Country
              </label>
              <input
                {...register("employeeDoj")}
                type="text"
                className={styles.inpttsc}
              />
            </div>
          </div>
          <div className={styles.twoinputasc}>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Loan ID
              </label>
              <input
                type="number"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                Driving License No Created On
              </label>
              <input
                type="date"
                className={styles.inpttsc}
                {...register("employeeCode")}
              />
            </div>
          </div>

          <div className={styles.buttonasc}>
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BorrowerForm;
