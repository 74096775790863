import React, { useState } from "react";
import styles from "./Table.module.css";
import { FaEye, FaSort } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import datas from "./data.json";
import profl from "./../../../icons/profl.jpg";
const Table = ({ rowData }) => {
  const [add, setAdd] = useState(false);

  const navigate = useNavigate();
  const handleSignIn = (borrowerId) => {
    navigate("/view-borrower-details", {
      state: { borrowerId },
    });
  };
  // console.log("rowData", rowData);
  return (
    <div
      className={styles.parenttabwrap}
      style={{ height: "calc(100vh - 220px)" }}
    >
      <div className={styles.tabwrap}>
        <table>
          <thead
            style={{
              position: "sticky",
              top: "0",
              background: "linear-gradient(to bottom, #12468a, #235392)",
              zIndex: "5",
              borderBottom: "1px solid #ddd",
            }}
          >
            <tr>
              <th style={{ minWidth: "40px" }}>#</th>
              <th
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr auto",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  minWidth: "140px",
                  border: "1px solid #ddd",
                  borderTop: "1px solid #ddd",
                  position: "sticky",
                  left: "0",
                  background: "linear-gradient(to bottom, #12468a, #235392)",
                }}
              >
                <span> Borrower Name</span>
                <FaSort />
              </th>

              <th style={{ minWidth: "120px" }}>Borrower ID</th>
              <th style={{ minWidth: "120px" }}>Step Status</th>
              <th style={{ minWidth: "120px" }}>Last Step</th>
              <th style={{ minWidth: "120px" }}> Mobile No</th>
              <th style={{ minWidth: "120px" }}>Date Of Birth</th>
              <th style={{ minWidth: "120px" }}>Marital Status</th>
              <th style={{ minWidth: "120px" }}>Spouse's Name</th>
              <th style={{ minWidth: "120px" }}>Father's Name</th>
              <th style={{ minWidth: "120px" }}>Gender</th>
              <th style={{ minWidth: "120px" }}>Alternate Number</th>
              <th style={{ minWidth: "120px" }}>Email Id</th>
              <th style={{ minWidth: "120px" }}>Pan No</th>
              <th style={{ minWidth: "120px" }}>Aadhar No</th>
              <th style={{ minWidth: "120px" }}>Driving License No</th>
              <th style={{ minWidth: "120px" }}>State</th>
              <th style={{ minWidth: "120px" }}>Country</th>
              <th style={{ minWidth: "120px" }}>Cibil</th>
              <th style={{ minWidth: "120px" }}>Postal</th>
              <th style={{ minWidth: "120px" }}>Loan Amount</th>
              <th style={{ minWidth: "120px" }}>Down Payment</th>
              <th style={{ minWidth: "120px" }}>Loan ID</th>
              <th style={{ minWidth: "120px" }}>Created On</th>
              {/* <th style={{ minWidth: "140px" }}>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {datas.data.map((row, index) => (
              <tr>
                {/* <td>{row.id}</td> */}
                <td>{index + 1}</td>
                <td
                  style={{
                    verticalAlign: "middle",
                    position: "sticky",
                    left: "0",
                    background: "#fff",
                  }}
                >
                  <img
                    src={profl}
                    style={{
                      borderRadius: "100%",
                      width: "30px",
                    }}
                  />

                  <span
                    style={{
                      position: "relative",
                      bottom: "10px",
                      paddingLeft: "10px",
                    }}
                  >
                    {row.borrower_name}
                  </span>
                </td>
                <td>{row.borrower_id}</td>
                <td>-</td>
                <td>{row.last_step?.details}</td>
                <td>{row.mobile_no}</td>
                <td>{row.date_of_birth}</td>
                <td>{row.marital_status}</td>
                <td>{row.spouse_name}</td>
                <td>{row.fathers_name}</td>
                <td>{row.gender}</td>
                <td>{row.alternate_number}</td>
                <td>{row.email_id}</td>
                <td>{row.pan_no}</td>
                <td>{row.aadhar_no}</td>
                <td>{row.driving_license_no}</td>
                <td>{row.state}</td>
                <td>{row.country}</td>
                <td>{row.cibil}</td>
                <td>{row.postal}</td>
                <td>{row.loan_amount}</td>
                <td>{row.down_payment}</td>
                <td>{row.loan_id}</td>
                <td>{row.created_on}</td>

                {/* <td>
                  {" "}
                  <button  type="button"
                    // onClick={() => handleEditRow(row)}
                    className={styles.editbtn}
                  >
                    <AiFillEdit
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button  type="button"
                    className={styles.viewbtn}
                    onClick={() => handleSignIn(row?.borrower_id)}
                  >
                    <FaEye
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                  <button  type="button" className={styles.dltbtn}>
                    <MdDelete
                      size={12}
                      style={{ position: "relative", top: "1px" }}
                    />
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
